<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('ListOfMedicineEquipmentTransfers') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2">
        <v-col
          cols="12"
          class="py-0"
          md="6"
          lg="3"
        >
          <v-autocomplete
            v-model="branchSelected"
            :items="branchList"
            item-text="shop_name"
            dense
            outlined
            :label="$t('select_branch')"
            item-value="shop_id_pri"
            @change="addPayload(payload.start,payload.end)"
          >
            <template v-slot:item="{ item }">
              {{ item.shop_name }}
              ({{ item.shop_id }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.shop_name }}
              ({{ item.shop_id }})
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:item.transfer_id="{ item }">
          <router-link
            :to="{ name: 'DetailTransferOut', params: { id: item.transfer_id_pri } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.transfer_id }}
          </router-link>
        </template>
        <template v-slot:item.transfer_create="{ item }">
          <ConverseDate :date="item.transfer_create" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import Comeback from '../Comeback.vue'
import branchReport from '@/api/branchReport'
import { i18n } from '@/plugins/i18n'
import { reportTransfer, removeComma } from '../useExcel'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { sumdate } from '@/plugins/filters'

export default {
  components: {
    DateFilters,
    Comeback,
    ConverseDate,
  },
  setup() {
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('a_number'), value: 'transfer_id', width: 100 },
      { text: i18n.t('transfer_to'), value: 'shop_name', width: 300 },
      {
        text: i18n.t('total_price'), value: 'transfer_price', width: 180, align: 'end',
      },
      { text: i18n.t('transfer_date'), value: 'transfer_create', width: 180 },
      { text: i18n.t('status'), value: 'transfer_status_name', width: 180 },
      {
        text: i18n.t('inspector'), value: 'user_fullname', width: 180, align: 'end',
      },

    ])
    const options = ref({})
    const exportLoading = ref(false)
    const branchList = ref([])
    const branchSelected = ref('')

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        shop_id_pri_to: branchSelected.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }
    branchReport.branchReportList().then(res => {
      branchList.value = res
      branchSelected.value = res[0].shop_id_pri
    })

    const searchReport = async payload => {
      loading.value = true
      const { data } = await reportTransfer(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      let dataExport = dataTableList.value.map((item, i) => {
        delete item.transfer_id_pri

        return { number: i + 1, ...item }
      })
      dataExport = await removeComma(
        JSON.parse(JSON.stringify(dataExport)),
      )
      const fileName = `${i18n.t(
        'ListOfMedicineEquipmentTransfers',
      )} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [
          `${i18n.t('ListOfMedicineEquipmentTransfers')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} `,
        ],
        [
          '#',
          i18n.t('a_number'),
          i18n.t('transfer_to'),
          i18n.t('total_price'),
          i18n.t('transfer_date'),
          i18n.t('status'),
          i18n.t('inspector'),
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'transfer_id',
          'shop_name',
          'transfer_price',
          'transfer_create',
          'transfer_status_name',
          'user_fullname',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]
      const wscols = [
        { wch: 6 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, 'sheet')

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      branchList,
      branchSelected,
      loading,
      dataTableList,
      columns,
      options,
      exportLoading,
      payload,
      exportExcel,
      mdiFileExcelOutline,
      addPayload,
    }
  },
}
</script>
